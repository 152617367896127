import { IDataResponse } from 'api/types/common';
import { AxiosResponse } from 'axios';

import { $host } from '..';
import { IAdminUser } from '../types/admin-users';

export const getAdminUsers = (): Promise<
  AxiosResponse<IDataResponse<IAdminUser[]>>
> => $host.get('/admin-users');

export const postAdminUser = (
  values: Pick<IAdminUser, 'email'>
): Promise<AxiosResponse<IDataResponse<IAdminUser>>> =>
  $host.post('/admin-users', { ...values });
