import { FC, useEffect, useState } from 'react';

import { DatePicker, Form, Input, Modal, Select } from 'antd';
import { ID, TISODate } from 'api/types/common';
import { IUser } from 'api/types/user';
import dayjs from 'dayjs';

interface Values {
  email: string;
  telegramId: string;
  city: string;
  fullName: string;
  birthDate: TISODate;
  employmentDate: TISODate;
}

interface UserCreateFormProps {
  open: boolean;
  initialValue: IUser | null;
  onSubmit: (values: Values, entity: string, id?: ID) => void;
  onCancel: () => void;
}

const dateFormat = 'DD.MM.YYYY';

export const UserCreateForm: FC<UserCreateFormProps> = ({
  open,
  initialValue,
  onSubmit,
  onCancel,
}) => {
  const [submittable, setSubmittable] = useState(false);

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const handleClose = () => {
    form.resetFields();
    setSubmittable(false);

    onCancel();
  };

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => {
        setSubmittable(true);
      })
      .catch((err) => {
        setSubmittable(false);
        console.log(err);
      });
  }, [form, values, open]);

  useEffect(() => {
    const convertedInitialValue = {
      ...initialValue,
      birthDate: initialValue?.birthDate
        ? dayjs(new Date(initialValue.birthDate))
        : null,
      employmentDate: initialValue?.employmentDate
        ? dayjs(new Date(initialValue.employmentDate))
        : null,
    };

    form.setFieldsValue(convertedInitialValue || {});
  }, [form, initialValue]);

  return (
    <Modal
      open={open}
      centered
      title={
        initialValue
          ? 'Редактирование пользователя'
          : 'Создание нового пользователя'
      }
      okText={initialValue ? 'Сохранить' : 'Создать'}
      cancelText="Отменить"
      okButtonProps={{
        disabled: !submittable,
      }}
      onCancel={handleClose}
      onOk={() => {
        form
          .validateFields()
          .then((values: Values) => {
            form.resetFields();

            onSubmit(
              values,
              initialValue ? 'updateUser' : 'createUser',
              initialValue?.id
            );
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="user-create"
        initialValues={{ modifier: 'public' }}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите почту',
            },
            {
              pattern: /^[\w.-]+@fojin\.tech$/,
              message: 'Пожалуйста, укажите почту в домене "fojin.tech"',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="telegramId"
          label="Telegram Id"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите Telegram ID',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="fullName"
          label="Полное имя"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите ФИО',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="birthDate"
          label="Дата рождения"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите дату рождения',
            },
          ]}
        >
          <DatePicker placeholder="Выбрать дату" format={dateFormat} />
        </Form.Item>
        <Form.Item
          name="employmentDate"
          label="Дата приема на работу"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите дату приема на работу',
            },
          ]}
        >
          <DatePicker placeholder="Выбрать дату" format={dateFormat} />
        </Form.Item>
        <Form.Item
          name="city"
          label="Город"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите город',
            },
          ]}
        >
          <Select
            options={[
              { value: 'Новороссийск', label: 'Новороссийск' },
              { value: 'Ростов-на-Дону', label: 'Ростов-на-Дону' },
              { value: 'Краснодар', label: 'Краснодар' },
              { value: 'Таганрог', label: 'Таганрог' },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
