import { FC, useEffect, useState } from 'react';

import { Form, Input, Modal } from 'antd';
import { ICategory } from 'api/types/category';
import { ID } from 'api/types/common';

interface Values {
  name: string;
}

interface CategoryCreactFormProps {
  open: boolean;
  onSubmit: (values: Values, entity: string, id?: ID) => void;
  initialValue: ICategory | null;
  onCancel: () => void;
}

export const CategoryCreactForm: FC<CategoryCreactFormProps> = ({
  open,
  onSubmit,
  onCancel,
  initialValue,
}) => {
  const [submittable, setSubmittable] = useState(false);

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const handleClose = () => {
    form.resetFields();
    setSubmittable(false);

    onCancel();
  };

  useEffect(() => {
    if (open) {
      form
        .validateFields({ validateOnly: true })
        .then(() => {
          setSubmittable(true);
        })
        .catch((err) => {
          setSubmittable(false);
          console.log(err);
        });
    }
  }, [form, values, open]);

  useEffect(() => {
    form.setFieldsValue(initialValue || {});
  }, [form, initialValue]);

  return (
    <Modal
      centered
      key="category"
      open={open}
      title={initialValue ? 'Редактирование категории' : 'Создание категории'}
      okText={initialValue ? 'Сохранить' : 'Создать'}
      cancelText="Отменить"
      onCancel={handleClose}
      okButtonProps={{
        disabled: !submittable,
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();

            onSubmit(
              values,
              initialValue ? 'updateCategory' : 'createCategory',
              initialValue?.id
            );
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="category-controll">
        <Form.Item
          name="name"
          label="Название категории"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите категорию',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
