import { Navigate, RouteObject, redirect } from 'react-router-dom';

import { ERole } from 'api/types/enums';
import { useApp } from 'lib/app';
import { configureLoaders, configureActions } from 'utils';

import {
  AdminsPage,
  loaders as adminLoaders,
  actions as adminActions,
} from './admins';
import {
  CatogoriesPage,
  loaders as categoriesLoaders,
  actions as categoriesActions,
} from './categories';
import { LoginPage } from './login';
import { OrderPage, loaders as orderLoaders } from './order';
import {
  OrdersPage,
  loaders as ordersLoaders,
  actions as ordersActions,
} from './orders';
import {
  ShopPage,
  loaders as productsLoaders,
  actions as productsActions,
} from './shop';
import {
  TransactionsPage,
  loaders as transactionLoaders,
  actions as transactionActions,
} from './transactions';
import {
  UserPage,
  loaders as userLoaders,
  actions as userActions,
} from './user';
import {
  UsersPage,
  loaders as usersLoaders,
  actions as usersActions,
} from './users';

export const MainRoutes = (): RouteObject[] | undefined => {
  const app = useApp();

  const routes: RouteObject[] = [
    {
      path: '/',
      loader: () => redirect('/users'),
    },
    {
      path: '/users',
      loader: configureLoaders(usersLoaders),
      action: configureActions(usersActions),
      element: <UsersPage />,
    },
    {
      path: '/users/:id',
      loader: configureLoaders(userLoaders),
      action: configureActions(userActions),
      element: <UserPage />,
    },
    {
      path: '/transactions',
      loader: configureLoaders(transactionLoaders),
      action: configureActions(transactionActions),
      element: <TransactionsPage />,
    },
    {
      path: '/categories',
      loader: configureLoaders(categoriesLoaders),
      action: configureActions(categoriesActions),
      element: <CatogoriesPage />,
    },
    {
      path: '/shop',
      loader: configureLoaders(productsLoaders),
      action: configureActions(productsActions),
      element: <ShopPage />,
    },
    {
      path: '/orders',
      action: configureActions(ordersActions),
      loader: configureLoaders(ordersLoaders),
      element: <OrdersPage />,
    },
    {
      path: '/orders/:id',
      loader: configureLoaders(orderLoaders),
      element: <OrderPage />,
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    // TODO: refactor Object.keys(ERole)[1] - need to fix
    app.auth.user?.role === Object.keys(ERole)[1]
      ? {
          path: '/admins',
          loader: configureLoaders(adminLoaders),
          action: configureActions(adminActions),
          element: <AdminsPage />,
        }
      : {},
    {
      path: '*',
      element: <Navigate to="/" />,
    },
  ];

  if (app.loading) return;

  return routes;
};
