import { FC, useEffect, useState } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { useDebounce } from 'hooks/useDebounce';

interface ISearchInputProps {
  className: string;
  onChange: (value: string) => void;
}

export const SearchInput: FC<ISearchInputProps> = ({ className, onChange }) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const debouncedSearchValue = useDebounce<string>(searchInputValue, 2000);

  useEffect(
    () => onChange(debouncedSearchValue),
    [debouncedSearchValue, onChange]
  );

  return (
    <Input
      className={className}
      size="middle"
      placeholder="Поиск по имени"
      prefix={<SearchOutlined />}
      value={searchInputValue}
      onChange={(e) => setSearchInputValue(e.currentTarget.value)}
    />
  );
};
