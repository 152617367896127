import { orderStatusTranslations } from 'constants/orders';

import { FC } from 'react';

import { Button, Checkbox, Space } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { makeOptionsFromEnum } from 'utils';

interface IOrdersFilterConfirmProps extends FilterDropdownProps {
  ordersStatusFilter: string[] | null;
}

export const OrdersFilterConfirm: FC<IOrdersFilterConfirmProps> = ({
  selectedKeys,
  setSelectedKeys,
  clearFilters,
  confirm,
  ordersStatusFilter,
}) => (
  <Space direction="vertical" style={{ padding: 8 }}>
    <Checkbox.Group
      style={{ display: 'flex', flexDirection: 'column' }}
      options={makeOptionsFromEnum(orderStatusTranslations)}
      onChange={(checkedValues) => setSelectedKeys(checkedValues as string[])}
      value={selectedKeys as string[]}
    />
    <Space>
      <Button
        size="small"
        onClick={() => clearFilters?.()}
        disabled={!selectedKeys.length}
      >
        Сбросить
      </Button>
      <Button
        size="small"
        type="primary"
        onClick={() => confirm()}
        disabled={
          selectedKeys.length && !!ordersStatusFilter
            ? selectedKeys.length === ordersStatusFilter.length &&
              selectedKeys.every((value) =>
                ordersStatusFilter.includes(value as string)
              )
            : !!selectedKeys.length === !!ordersStatusFilter
        }
      >
        Применить
      </Button>
    </Space>
  </Space>
);
