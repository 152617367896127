import { Skeleton, SkeletonProps, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

export type SkeletonTableColumnsType = {
  key: string;
};

type SkeletonTableProps = SkeletonProps & {
  columns: ColumnsType<SkeletonTableColumnsType>;
  rowCount?: number;
};

export const SkeletonTable = ({
  loading = false,
  active = false,
  rowCount = 10,
  columns,
  children,
  className,
}: SkeletonTableProps) =>
  loading ? (
    <Table
      rowKey="key"
      pagination={false}
      dataSource={[...Array(rowCount)].map((_, index) => ({
        key: `key${index}`,
      }))}
      columns={columns.map((column) => ({
        title: (
          <Skeleton
            title
            active={active}
            paragraph={false}
            className={className}
          />
        ),
        render: function renderPlaceholder() {
          return (
            <Skeleton
              key={column.key}
              title
              active={active}
              paragraph={false}
              className={className}
            />
          );
        },
      }))}
    />
  ) : (
    <>{children}</>
  );
