import { useEffect, useState } from 'react';
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import Title from 'antd/es/typography/Title';
import { IPagginatedDataResponse, ITypeFilter } from 'api/types/common';
import { ICurrency } from 'api/types/currency';
import { EUserStatus } from 'api/types/enums';
import { IOrder } from 'api/types/orders';
import { ITransaction } from 'api/types/transactions';
import { IUser } from 'api/types/user';

import { Currencies } from './currencies';
import { PersonalInformation } from './personal-information';
import { UserOrders } from './user-orders';
import { UserTransactions } from './user-transactions';

import styles from './user.module.scss';

export const UserPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [activeTab, setActiveTab] = useState(() => {
    const currencyId = searchParams.get('currencyId');

    if (currencyId) return currencyId;

    return '1';
  });

  const [typeFilter, setTypeFilter] = useState<ITypeFilter>(() => {
    const type = searchParams.get('type');

    const initialTypeFilter: ITypeFilter = {
      '1': { filter: null },
      '2': { filter: null },
      '3': { filter: null },
    };

    if (type) {
      initialTypeFilter[activeTab].filter = type === 'all' ? null : type;
    }

    return initialTypeFilter;
  });

  const { user, currencies, userOrders, transactions } = useLoaderData() as {
    user: IUser;
    currencies: ICurrency[];
    userOrders: IPagginatedDataResponse<IOrder[]>;
    transactions: IPagginatedDataResponse<ITransaction[]>;
  };

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set(
      'type',
      typeFilter[activeTab].filter
        ? (typeFilter[activeTab].filter as string)
        : 'all'
    );

    newSearchParams.set('currencyId', activeTab);

    setSearchParams(newSearchParams);

    navigate(`${location.pathname}?${newSearchParams.toString()}`, {
      replace: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActiveTab = (activeTab: string) => setActiveTab(activeTab);

  const handleTypeFilter = (typeFilter: string | null) => {
    setTypeFilter((state) => ({
      ...state,
      [activeTab]: {
        filter: typeFilter ? typeFilter : null,
      },
    }));
  };

  const handleRedirectToPreviousPage = () => navigate(-1);

  return (
    <div className={styles.user}>
      <div className={styles.header}>
        <Button
          onClick={handleRedirectToPreviousPage}
          icon={<ArrowLeftOutlined style={{ color: 'gray' }} />}
        />
        <div className={styles.header__text}>
          <Title className={styles.header__text__heading}>
            {user.fullName}
          </Title>
          <Tag color={user.status === EUserStatus.BANNED ? 'red' : 'green'}>
            {user.status === EUserStatus.BANNED ? 'Заблокирован' : 'Активный'}
          </Tag>
        </div>
      </div>
      <PersonalInformation user={user} />
      <Currencies
        user={user}
        currencies={currencies}
        activeTab={activeTab}
        typeFilter={typeFilter}
      />
      <UserOrders orders={userOrders.result} userId={user.id} />
      <UserTransactions
        transactions={transactions.result}
        currencies={currencies}
        onActiveTab={handleActiveTab}
        onTypeFilter={handleTypeFilter}
        activeTab={activeTab}
        typeFilter={typeFilter}
      />
    </div>
  );
};
