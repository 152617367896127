import { currencyTranslations } from 'constants/currencies';

import { FC, useEffect, useState } from 'react';

import { Checkbox, Form, Input, Modal, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { ICategory } from 'api/types/category';
import { ID } from 'api/types/common';
import { ICurrency } from 'api/types/currency';
import { ECurrency } from 'api/types/enums';
import { IProduct } from 'api/types/products';

interface Values {
  name: string;
  price: number;
  currencyId: number;
  categoryId: number;
  isAvailable: boolean;
  description: string;
  imageUrl: string | null;
}

interface ProductCreactFormProps {
  open: boolean;
  currencies: ICurrency[];
  categories: ICategory[];
  onSubmit: (values: Values, entity: string, id?: ID) => void;
  initialValue: IProduct | null;
  onCancel: () => void;
}

export const ProductCreactForm: FC<ProductCreactFormProps> = ({
  open,
  currencies,
  categories,
  onSubmit,
  onCancel,
  initialValue,
}) => {
  const [submittable, setSubmittable] = useState(false);

  const CATEGORIES_OPTIONS = categories.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const CURRENCIES_OPTIONS = currencies
    .filter((currency) => currency.name !== ECurrency.THANKS)
    .map(({ id, name }) => ({
      value: id,
      label: currencyTranslations[name],
    }));

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const handleClose = () => {
    form.resetFields();
    setSubmittable(false);

    onCancel();
  };

  const validateNumberString = (rule: any, value: any, callback: any) => {
    if (/^\d+$/.test(value)) {
      callback();
    } else {
      callback('Пожалуйста, введите число');
    }
  };

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => {
        setSubmittable(true);
      })
      .catch((err) => {
        setSubmittable(false);
        console.log(err);
      });
  }, [form, values, open]);

  useEffect(() => {
    form.setFieldsValue(initialValue || {});
  }, [form, initialValue]);

  return (
    <Modal
      centered
      key="currency"
      open={open}
      title={initialValue ? 'Редактирование товара' : 'Создание товара'}
      okText={initialValue ? 'Сохранить' : 'Создать'}
      cancelText="Отменить"
      onCancel={handleClose}
      okButtonProps={{
        disabled: !submittable,
      }}
      onOk={() => {
        form
          .validateFields()
          .then(
            ({
              name,
              price,
              categoryId,
              currencyId,
              isAvailable,
              description,
              imageUrl,
            }: Values) => {
              form.resetFields();

              const formData: Values = {
                name,
                price: Number(price),
                categoryId: Number(categoryId),
                currencyId: Number(currencyId),
                description,
                imageUrl: imageUrl ? imageUrl : null,
                isAvailable: isAvailable !== undefined ? isAvailable : true,
              };

              onSubmit(
                formData,
                initialValue ? 'updateProduct' : 'createProduct',
                initialValue?.id
              );
            }
          )
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="product-create">
        <Form.Item
          name="name"
          label="Название товара"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите название товара',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="currencyId"
          label="Валюта"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите валюту',
            },
          ]}
        >
          <Select options={CURRENCIES_OPTIONS} />
        </Form.Item>
        <Form.Item
          name="price"
          label="Цена"
          rules={[
            { required: true, message: '' },
            { validator: validateNumberString },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="categoryId"
          label="Категория"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите категорию',
            },
          ]}
        >
          <Select options={CATEGORIES_OPTIONS} />
        </Form.Item>
        <Form.Item name="isAvailable" valuePropName="checked">
          <Checkbox defaultChecked={true}>Доступен к заказу</Checkbox>
        </Form.Item>
        <Form.Item
          name="description"
          label="Описание"
          rules={[
            {
              max: 300,
              message: 'Максимальное количество символов - 300',
            },
          ]}
        >
          <TextArea style={{ resize: 'none' }} rows={3} />
        </Form.Item>
        <Form.Item name="imageUrl" label="Ссылка на изображение">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
