import { currencyIcon, currencyTranslations } from 'constants/currencies';
import { orderStatusColor, orderStatusTranslations } from 'constants/orders';

import { Tag } from 'antd';
import { ICategory } from 'api/types/category';
import { ITableRows } from 'api/types/common';
import { ICurrency } from 'api/types/currency';
import { EOrderStatus } from 'api/types/enums';
import { IOrder, IOrderHistory } from 'api/types/orders';
import { IProduct } from 'api/types/products';
import { IUser } from 'api/types/user';
import { format } from 'date-fns';

export const orderTableRows: ITableRows<
  Pick<IOrder, 'status' | 'date' | 'statusHistory' | 'user'>
>[] = [
  {
    key: 'statusHistory',
    label: 'Кем создан',
    render: (record: IOrder, value: IOrderHistory[]) => {
      if (value.length) {
        if (value[0]?.newStatus === value[0]?.oldStatus) {
          return `${value[0]?.changedUser?.email} (Администратор)`;
        }
      }

      return record.user.fullName;
    },
  },
  {
    key: 'status',
    label: 'Статус',
    render: (_, value: EOrderStatus) => (
      <Tag color={orderStatusColor[value]}>
        {orderStatusTranslations[value]}
      </Tag>
    ),
  },
  {
    key: 'date',
    label: 'Дата заказа',
    render: (_, value) => format(new Date(value), 'dd.MM.yyyy'),
  },
  {
    key: 'date',
    label: 'Время заказа',
    render: (_, value) => format(new Date(value), 'HH:mm'),
  },
];

export const userTableRows: ITableRows<
  Pick<IUser, 'email' | 'fullName' | 'city'>
>[] = [
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'fullName',
    label: 'Имя',
  },
  {
    key: 'city',
    label: 'Город',
  },
];

export const productTableRows: ITableRows<
  Omit<IProduct, 'id' | 'categoryId' | 'currencyId' | 'imageUrl'>
>[] = [
  {
    key: 'name',
    label: 'Название',
  },
  {
    key: 'currency',
    label: 'Валюта',
    render: (_, value: ICurrency) =>
      `${currencyTranslations[value.name]} ${currencyIcon[value.name]} `,
  },
  {
    key: 'price',
    label: 'Цена',
  },
  {
    key: 'category',
    label: 'Категория',
    render: (_, value: ICategory) => (value ? value.name : '-'),
  },
  {
    key: 'isAvailable',
    label: 'Доступен к заказу',
    render: (_, value) => (value ? 'Да' : 'Нет'),
  },
  {
    key: 'description',
    label: 'Описание',
  },
];
