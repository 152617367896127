import { ITableRows } from 'api/types/common';
import { IUser } from 'api/types/user';
import { format } from 'date-fns';

export const tableRows: ITableRows<
  Omit<
    IUser,
    | 'status'
    | 'balanceThanks'
    | 'balanceDiamonds'
    | 'balanceCoins'
    | 'id'
    | 'createdAt'
  >
>[] = [
  { key: 'fullName', label: 'Имя' },
  { key: 'email', label: 'Email' },
  { key: 'telegramId', label: 'Telegram id' },
  { key: 'city', label: 'Город' },
  {
    key: 'birthDate',
    label: 'День рождения',
    render: (_, value) => format(new Date(value), 'dd.MM.yyyy'),
  },
  {
    key: 'employmentDate',
    label: 'Дата трудоустройства',
    render: (_, value) => format(new Date(value), 'dd.MM.yyyy'),
  },
];
