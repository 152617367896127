import { HTMLAttributes, useMemo, useRef, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';

import {
  EditOutlined,
  UserAddOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import { Space, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ID } from 'api/types/common';
import { EUserStatus } from 'api/types/enums';
import { IUser } from 'api/types/user';
import cn from 'clsx';
import { UserCreateForm } from 'components/forms/user/user-create';
import { SearchInput } from 'components/search-input';
import { useSubmit } from 'hooks/useSubmit';
import { useTableHeight } from 'hooks/useTableHeight';
import { PageLayout } from 'layouts/page';
import { filterAndSortUsers } from 'utils';

import styles from './users.module.scss';

export const UsersPage = () => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [editValue, setEditValue] = useState<IUser | null>(null);

  const navigate = useNavigate();
  const [userCreateFormIsOpen, setUserCreateFormIsOpen] = useState(false);

  const { users } = useLoaderData() as {
    users: IUser[];
  };

  const sortedUsers = useMemo(
    () => filterAndSortUsers(users, searchInputValue),
    [users, searchInputValue]
  );

  const { submit } = useSubmit(() => {
    setUserCreateFormIsOpen(false);
  });

  const tableHeight = useTableHeight(tableContainerRef, tableHeadRef);

  const handleEdit = (data: IUser) => {
    setUserCreateFormIsOpen(true);
    setEditValue(data);
  };

  const handleRowClick = (id: ID) => navigate(`/users/${id}`);

  const handleSearchChange = (value: string) => setSearchInputValue(value);

  const handleDelete = (id: ID) => {
    submit({
      entity: 'deleteUser',
      data: id,
      queryKeys: ['users'],
    });
  };

  const handleSubmit = (values: any, entity: string, id?: ID) => {
    submit({
      entity: entity,
      data: id ? { values: values, id } : { ...values, status: 'active' },
      queryKeys: ['users'],
    });

    if (editValue) {
      setEditValue(null);
    }
  };

  const columns: ColumnsType<IUser> = [
    {
      title: 'Полное имя',
      dataIndex: 'fullName',
      key: 'fullName',
      className: styles.cell,
      render: (value, record) => (
        <div
          className={cn({
            [styles.banned]: record.status === EUserStatus.BANNED,
          })}
          style={{ minWidth: '140px' }}
        >
          {value}
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      className: styles.cell,
      render: (value, record) => (
        <div
          className={cn({
            [styles.banned]: record.status === EUserStatus.BANNED,
          })}
          style={{ minWidth: '60px' }}
        >
          {value}
        </div>
      ),
    },
    {
      title: 'Tg Id',
      dataIndex: 'telegramId',
      key: 'telegramId',
      className: styles.cell,
      render: (value, record) => (
        <div
          className={cn({
            [styles.banned]: record.status === EUserStatus.BANNED,
          })}
          style={{ minWidth: '80px' }}
        >
          {value}
        </div>
      ),
    },
    {
      title: 'Город',
      dataIndex: 'city',
      key: 'city',
      className: styles.cell,
      render: (value, record) => (
        <div
          className={cn({
            [styles.banned]: record.status === EUserStatus.BANNED,
          })}
          style={{ minWidth: '80px' }}
        >
          {value}
        </div>
      ),
    },
    {
      title: 'Действие',
      key: 'action',
      className: styles.actions,
      width: '10%',
      render: (data) => (
        <Space size="small">
          <Tooltip title="Редактирование">
            <Button
              onClick={(e) => {
                e.stopPropagation();

                handleEdit(data);
              }}
            >
              <EditOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Удаление">
            <Popconfirm
              cancelText="Отменить"
              title="Вы уверенны, что хотите удалить пользователя?"
              onCancel={(e) => e?.stopPropagation()}
              onConfirm={(e) => {
                e?.stopPropagation();
                handleDelete(data.id);
              }}
            >
              <Button onClick={(e) => e.stopPropagation()}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  if (!users) return null;

  return (
    <>
      <PageLayout
        title="Пользователи"
        actions={[
          <Button
            onClick={() => setUserCreateFormIsOpen(true)}
            icon={<UserAddOutlined />}
            type="primary"
          >
            Новый пользователь
          </Button>,
          <SearchInput
            className={styles.searchInput}
            onChange={handleSearchChange}
          />,
        ]}
      >
        <div
          ref={tableContainerRef}
          style={{
            display: 'flex',
            height: tableHeight,
            flex: '1 1 auto',
          }}
        >
          <Table
            rowClassName={(record) =>
              cn(styles.tableRow, {
                [styles.bannedRow]: record.status === EUserStatus.BANNED,
              })
            }
            rowKey="id"
            bordered
            pagination={false}
            columns={columns}
            dataSource={sortedUsers}
            scroll={{ y: tableHeight, x: 'max-content' }}
            components={{
              header: {
                wrapper: (props: HTMLAttributes<HTMLTableSectionElement>) => (
                  <thead {...props} ref={tableHeadRef} />
                ),
              },
            }}
            onRow={(record) => ({
              onClick: () => handleRowClick(record.id),
            })}
          />
        </div>
      </PageLayout>
      <UserCreateForm
        open={userCreateFormIsOpen}
        initialValue={editValue}
        onSubmit={handleSubmit}
        onCancel={() => {
          setUserCreateFormIsOpen(false);
          setEditValue(null);
        }}
      />
    </>
  );
};
