import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { RootBoundary } from 'components/error-boundary';
import { MainLayout } from 'layouts/main';

import { MainRoutes } from './routes';

const router = () =>
  createBrowserRouter([
    {
      path: '/',
      element: <MainLayout />,
      errorElement: <RootBoundary />,
      children: MainRoutes(),
    },
  ]);

export const AppRoutes = () => <RouterProvider router={router()} />;
