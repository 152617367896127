import { ID } from 'api/types/common';
import { IUser } from 'api/types/user';
import { deleteUser, postUser, updateUser } from 'api/users';

export const actions = {
  createUser: async (
    data: Pick<
      IUser,
      | 'telegramId'
      | 'status'
      | 'fullName'
      | 'city'
      | 'employmentDate'
      | 'birthDate'
    >
  ) => {
    await postUser(data);
  },

  updateUser: async (data: {
    values: Pick<
      IUser,
      | 'telegramId'
      | 'status'
      | 'fullName'
      | 'city'
      | 'employmentDate'
      | 'birthDate'
    >;
    id: ID;
  }) => {
    await updateUser(data.values, data.id);
  },

  deleteUser: async (id: ID) => {
    await deleteUser(id);
  },
};
