import { TOrderStatusColor, TTranslationMap } from 'api/types/common';
import { EOrderStatus } from 'api/types/enums';

export const orderStatusTranslations: TTranslationMap<EOrderStatus> = {
  [EOrderStatus.IN_PROGRESS]: 'В работе',
  [EOrderStatus.COMPLETED]: 'Выполнен',
  [EOrderStatus.PENDING]: 'Создан',
  [EOrderStatus.CANCELED]: 'Отменен',
};

export const orderStatusColor: TOrderStatusColor = {
  [EOrderStatus.IN_PROGRESS]: 'blue',
  [EOrderStatus.COMPLETED]: 'green',
  [EOrderStatus.PENDING]: 'orange',
  [EOrderStatus.CANCELED]: 'magenta',
};
