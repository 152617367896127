import { ID, IDataResponse } from 'api/types/common';
import { IOrder, IPostOrderRequestArgs } from 'api/types/orders';
import { AxiosResponse } from 'axios';

import { $host } from '..';

import { IPagginatedDataResponse } from './../types/common';

export const getOrders = (): Promise<
  AxiosResponse<IDataResponse<IPagginatedDataResponse<IOrder[]>>>
> => $host.get('/admin/orders');

export const getOrder = (
  id: ID
): Promise<AxiosResponse<IDataResponse<IOrder[]>>> =>
  $host.get(`/admin/orders/${id}`);

export const postOrder = (
  data: IPostOrderRequestArgs
): Promise<AxiosResponse<IDataResponse<IOrder[]>>> =>
  $host.post('/admin/orders', { ...data });

export const updateOrder = ({
  status,
  id,
  userId,
}: { userId: ID } & Pick<IOrder, 'status' | 'id'>): Promise<
  AxiosResponse<IDataResponse<IOrder[]>>
> => $host.patch(`/admin/orders/${id}`, { status, userId });

export const cancelOrder = (data: {
  description: string;
  id: ID;
}): Promise<AxiosResponse<IDataResponse<IOrder[]>>> =>
  $host.post(`/admin/orders/${data.id}/refund`, {
    description: data.description,
  });
