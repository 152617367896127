import { ReactElement } from 'react';

import { Typography } from 'antd';
import { ITableRows } from 'api/types/common';

import styles from './data-table.module.scss';

interface DataTableProps<T> {
  rows: ITableRows<T>[];
  value: T;
}

const { Text } = Typography;

export const DataTable = <T,>({
  rows,
  value,
}: DataTableProps<T>): ReactElement => (
  <table>
    {rows.map(({ key, label, render }) => (
      <tr>
        <td className={styles.cell}>
          <Text className={styles.text} strong>
            {label}:
          </Text>
        </td>
        <td>
          <Text>{render ? render(value, value[key]) : value[key] ?? '-'}</Text>
        </td>
      </tr>
    ))}
  </table>
);
