import { FC, useEffect, useState } from 'react';

import { Form, Input, Modal } from 'antd';
import { ID } from 'api/types/common';

interface Values {
  description: string;
}

interface OrderCancelFormProps {
  open: boolean;
  id: ID | null;
  onSubmit: (values: Values, entity: string, id: ID) => void;
  onCancel: () => void;
}

export const OrderCancelForm: FC<OrderCancelFormProps> = ({
  open,
  id,
  onSubmit,
  onCancel,
}) => {
  const [submittable, setSubmittable] = useState(false);
  const [form] = Form.useForm();

  // Watch all values
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values, open]);

  const handleClose = () => {
    form.resetFields();

    onCancel();
  };

  return (
    <Modal
      centered
      key="order"
      open={open}
      title="Отменить заказ"
      okText="Отправить"
      cancelText="Назад"
      onCancel={handleClose}
      okButtonProps={{ disabled: !submittable }}
      onOk={() => {
        form
          .validateFields()
          .then((values: Values) => {
            form.resetFields();

            if (id) {
              onSubmit(values, 'cancelOrder', id);
            }
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="order-cancel">
        <Form.Item
          name="description"
          label="Комментарий"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите комментарий',
            },
            {
              max: 300,
              message: 'Максимальное количество символов - 300',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
