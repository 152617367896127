import { errorsTranslations } from 'constants/errors';

import { QueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import axios from 'axios';
import { isErrorEnumKey } from 'utils';

export const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 1000 * 10 } },
});

const $host = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

$host.interceptors.request.use((config) => {
  const token = new URLSearchParams(document.location.search).get('token');

  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }

  return config;
});

$host.interceptors.response.use(
  (response) => response,
  (err) => {
    const error = err.response;

    const errorMessage = error.data.message || 'Произошла ошибка';

    if (error.status === 401) {
      localStorage.removeItem('token');
    } else {
      message.error(
        isErrorEnumKey(errorMessage)
          ? errorsTranslations[errorMessage]
          : errorMessage,
        5
      );
    }

    return Promise.reject(err);
  }
);

$host.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(
  'token'
)}`;

export { $host };
