import { errorsTranslations } from 'constants/errors';

import {
  LoaderFunctionArgs,
  Params,
  ActionFunctionArgs,
} from 'react-router-dom';

import { queryClient } from 'api';
import { IDataResponse } from 'api/types/common';
import { EUserStatus, TErrorMessages } from 'api/types/enums';
import { IUser } from 'api/types/user';
import { AxiosError, AxiosResponse } from 'axios';

interface IRequests {
  name: string | (() => string);
  fn: () => Promise<AxiosResponse<IDataResponse<unknown>>>;
}

export const configureLoaders =
  (callback: ({ params, request }: LoaderFunctionArgs<any>) => IRequests[]) =>
  async ({ params, request }: LoaderFunctionArgs<any>) => {
    if (Object.values(params).some((value) => !value)) {
      throw new Response('Not Found', { status: 404 });
    }

    const requests = callback({ params, request }).map(({ name, fn }) => ({
      name: typeof name === 'function' ? name() : name,
      fn,
    }));

    try {
      const res = await Promise.all(
        requests.map(({ name, fn }) =>
          queryClient.fetchQuery({ queryKey: [name], queryFn: fn })
        )
      );

      return res.reduce(
        (acc, _res, index) => ({
          ...acc,
          [requests[index].name.split('/')[0]]: _res?.data?.data,
        }),
        {}
      );
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 401) {
          throw new Response('Unauthorized', { status: 401 });
        }

        throw new Error(e.message);
      }
    }
  };

export const configureActions =
  (
    entities: Record<
      string,
      (data: any, params: Params<string>) => Promise<void>
    >
  ) =>
  async ({ params, request }: ActionFunctionArgs<any>) => {
    const { entity, data, queryKeys } = await request.json();

    try {
      await entities[entity ?? 'default'](data, params);

      queryKeys.forEach((queryKey: string) => {
        queryClient.invalidateQueries({ queryKey: [queryKey] });
      });

      return {
        ok: true,
      };
    } catch (e) {
      console.log(`This error occuered in entity: ${entity} :>> `, e);

      return e;
    }
  };

export const reorderQueue = <T>(
  queue: Array<T>,
  startIndex: number,
  endIndex: number
): Array<T> => {
  const updatedQueue = [...queue];

  const movedItem = updatedQueue[startIndex];
  updatedQueue.splice(startIndex, 1);
  updatedQueue.splice(endIndex, 0, movedItem);

  return updatedQueue.map((item, index) => ({
    ...item,
    order: index,
  }));
};

export const makeOptionsFromEnum = (enumValues: Record<string, string>) => {
  const values = Array.from(Object.entries(enumValues));

  return values.map((level) => ({
    label: level[1],
    value: level[0],
  }));
};

export const makeTabItemsFromEnum = (enumValues: Record<string, string>) => {
  const values = Array.from(Object.entries(enumValues));

  return values.map((level) => ({
    key: level[0],
    label: level[1],
  }));
};

export const filterAndSortUsers = (users: IUser[], searchInputValue: string) =>
  users
    .filter((user) =>
      user.fullName.toLowerCase().includes(searchInputValue.toLowerCase())
    )
    .sort((a, b) => {
      if (a.status === EUserStatus.BANNED && b.status !== EUserStatus.BANNED) {
        return 1;
      }

      if (a.status !== EUserStatus.BANNED && b.status === EUserStatus.BANNED) {
        return -1;
      }

      return 0;
    });

export const isErrorEnumKey = (key: string): key is TErrorMessages =>
  Object.prototype.hasOwnProperty.call(
    errorsTranslations,
    key as TErrorMessages
  );
