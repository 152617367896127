import { useState } from 'react';
import {
  URLSearchParamsInit,
  useLoaderData,
  useSearchParams,
} from 'react-router-dom';

import { IPagginatedDataResponse, ITypeFilter } from 'api/types/common';
import { ICurrency } from 'api/types/currency';
import { ITransaction } from 'api/types/transactions';
import { TransactionTable } from 'components/tables/transaction-table';
import { PageLayout } from 'layouts/page';

export const TransactionsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [activeTab, setActiveTab] = useState(() => {
    const currencyId = searchParams.get('currencyId');

    if (currencyId) return currencyId;

    return '1';
  });

  const [typeFilter, setTypeFilter] = useState<ITypeFilter>(() => {
    const type = searchParams.get('type');

    const initialTypeFilter: ITypeFilter = {
      '1': { filter: null },
      '2': { filter: null },
      '3': { filter: null },
    };

    if (type) {
      initialTypeFilter[activeTab].filter = type === 'all' ? null : type;
    }

    return initialTypeFilter;
  });

  const { transactions, currencies } = useLoaderData() as {
    transactions: IPagginatedDataResponse<ITransaction[]>;
    currencies: ICurrency[];
  };

  const handleSearchParams = (params: URLSearchParamsInit) =>
    setSearchParams(params);

  const handleTypeFilter = (typeFilter: string | null) => {
    setTypeFilter((state) => ({
      ...state,
      [activeTab]: {
        filter: typeFilter ? typeFilter : null,
      },
    }));
  };

  const handleActiveTab = (activeTab: string) => setActiveTab(activeTab);

  return (
    <PageLayout title="Транзакции">
      <TransactionTable
        transactions={transactions.result}
        searchParams={searchParams}
        activeTab={activeTab}
        typeFilter={typeFilter}
        onSearchParams={handleSearchParams}
        onTypeFilter={handleTypeFilter}
        currencies={currencies}
        onActiveTab={handleActiveTab}
      />
    </PageLayout>
  );
};
