import { HTMLAttributes, useRef, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { Space, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { IAdminUser } from 'api/types/admin-users';
import { ERole } from 'api/types/enums';
import { AdminCreateForm } from 'components/forms/admin/admin-create';
import { format } from 'date-fns';
import { useSubmit } from 'hooks/useSubmit';
import { useTableHeight } from 'hooks/useTableHeight';
import { PageLayout } from 'layouts/page';

import styles from './admins.module.scss';

export const AdminsPage = () => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);

  const [adminCreateFormIsOpen, setAdminCreateFormIsOpen] = useState(false);

  const tableHeight = useTableHeight(tableContainerRef, tableHeadRef);

  const { admins } = useLoaderData() as {
    admins: IAdminUser[];
  };

  const { submit } = useSubmit(() => {
    setAdminCreateFormIsOpen(false);
  });

  const handleCreate = (values: any) => {
    submit({
      entity: 'test',
      data: { ...values },
      queryKeys: ['admins'],
    });
  };

  const columns: ColumnsType<IAdminUser> = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      className: styles.cell,
      render: (data) => <div style={{ minWidth: '60px' }}>{data}</div>,
    },
    {
      title: 'Google Id',
      dataIndex: 'googleId',
      key: 'googleId',
      className: styles.cell,
      render: (data) => <div style={{ minWidth: '90px' }}>{data}</div>,
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
      className: styles.cell,
      render: (data) => (
        <div style={{ minWidth: '70px' }}>
          {ERole[data as keyof typeof ERole]}
        </div>
      ),
    },
    {
      title: 'Создан',
      key: 'createdAt',
      dataIndex: 'createdAt',
      className: styles.cell,
      render: (data) => (
        <div style={{ minWidth: '70px' }}>
          {format(new Date(data), 'dd.MM.yyyy')}
        </div>
      ),
    },
    {
      title: 'Действие',
      key: 'action',
      width: '10%',
      className: styles.actions,
      render: () => (
        <Space size="middle">
          <Tooltip title="Удаление">
            <Button>
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageLayout
        title="Администраторы"
        actions={[
          <Button
            onClick={() => setAdminCreateFormIsOpen(true)}
            icon={<UserAddOutlined />}
            type="primary"
          >
            Новый администратор
          </Button>,
        ]}
      >
        <div
          ref={tableContainerRef}
          style={{
            display: 'flex',
            height: tableHeight,
            flex: '1 1 auto',
          }}
        >
          <Table
            className={styles.table}
            rowKey="id"
            bordered
            pagination={false}
            columns={columns}
            dataSource={admins}
            scroll={{ y: tableHeight, x: 'max-content' }}
            components={{
              header: {
                wrapper: (props: HTMLAttributes<HTMLTableSectionElement>) => (
                  <thead {...props} ref={tableHeadRef} />
                ),
              },
            }}
          />
        </div>
      </PageLayout>
      <AdminCreateForm
        open={adminCreateFormIsOpen}
        onCreate={handleCreate}
        onCancel={() => setAdminCreateFormIsOpen(false)}
      />
    </>
  );
};
