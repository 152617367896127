import { HTMLAttributes, useRef, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ICategory } from 'api/types/category';
import { ID, IPagginatedDataResponse } from 'api/types/common';
import { DraggableCategory } from 'components/draggable-category';
import { CategoryCreactForm } from 'components/forms/category/category-create';
import { useSubmit } from 'hooks/useSubmit';
import { useTableHeight } from 'hooks/useTableHeight';
import { PageLayout } from 'layouts/page';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import styles from './categories.module.scss';

export const CatogoriesPage = () => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);

  const [categoryCreateFormIsOpen, setCategoryCreateFormIsOpen] =
    useState(false);

  const [editValue, setEditValue] = useState<ICategory | null>(null);

  const { categories } = useLoaderData() as {
    categories: IPagginatedDataResponse<ICategory[]>;
  };

  const { submit } = useSubmit(() => {
    setCategoryCreateFormIsOpen(false);
  });

  const tableHeight = useTableHeight(tableContainerRef, tableHeadRef);

  const handleDelete = (id: ID) => {
    submit({
      entity: 'deleteCategory',
      data: { id },
      queryKeys: ['categories'],
    });
  };

  const handleEdit = (data: ICategory) => {
    setCategoryCreateFormIsOpen(true);
    setEditValue(data);
  };

  const handleSubmit = (values: any, entity: string, id?: ID) => {
    submit({
      entity: entity,
      data: { ...values, id },
      queryKeys: ['categories'],
    });

    if (editValue) {
      setEditValue(null);
    }
  };

  const columns: ColumnsType<ICategory> = [
    {
      title: 'Категория',
      dataIndex: 'name',
      key: 'name',
      className: styles.cell,
      width: '60%',
      render: (_, record) => (
        <DraggableCategory category={record} categories={categories.result} />
      ),
    },
    {
      title: 'Действие',
      key: 'action',
      className: styles.actions,
      width: '10%',
      render: (data) => (
        <Space className={styles.actionsContent} size="small">
          <Tooltip title="Редактирование">
            <Button
              onClick={(e) => {
                e.preventDefault();

                handleEdit(data);
              }}
            >
              <EditOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Удаление">
            <Popconfirm
              title="Вы уверены?"
              cancelText="Отменить"
              onConfirm={(e) => {
                e?.preventDefault();

                handleDelete(data.id);
              }}
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageLayout
        title="Конфигурация категорий"
        actions={[
          <Button
            onClick={() => setCategoryCreateFormIsOpen(true)}
            icon={<PlusOutlined />}
            type="primary"
          >
            Новая категория
          </Button>,
        ]}
      >
        <div
          ref={tableContainerRef}
          style={{
            display: 'flex',
            height: tableHeight,
            flex: '1 1 auto',
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <Table
              className={styles.table}
              rowKey="id"
              bordered
              pagination={false}
              columns={columns}
              dataSource={categories.result}
              scroll={{ y: tableHeight, x: 'max-content' }}
              components={{
                header: {
                  wrapper: (props: HTMLAttributes<HTMLTableSectionElement>) => (
                    <thead {...props} ref={tableHeadRef} />
                  ),
                },
              }}
            />
          </DndProvider>
        </div>
      </PageLayout>
      <CategoryCreactForm
        open={categoryCreateFormIsOpen}
        onSubmit={handleSubmit}
        initialValue={editValue}
        onCancel={() => {
          setCategoryCreateFormIsOpen(false);
          setEditValue(null);
        }}
      />
    </>
  );
};
