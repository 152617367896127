import { FC, useState } from 'react';

import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';

export const ControlledSelect: FC<Omit<SelectProps, 'open' | 'onClick'>> = (
  props
) => {
  const [open, setOpen] = useState(false);

  return (
    <Select
      open={open}
      onClick={(e) => {
        e.stopPropagation();
        setOpen((state) => !state);
      }}
      {...props}
    />
  );
};
