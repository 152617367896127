import { currencyIcon } from 'constants/currencies';
import { orderStatusColor, orderStatusTranslations } from 'constants/orders';

import { HTMLAttributes, useMemo, useRef, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Table, Tag, Tooltip } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { ID, IPagginatedDataResponse } from 'api/types/common';
import { EOrderStatus } from 'api/types/enums';
import { IOrder } from 'api/types/orders';
import { IProduct } from 'api/types/products';
import { IUser } from 'api/types/user';
import { ControlledSelect } from 'components/controlled-select';
import { OrderCancelForm } from 'components/forms/order/order-cancel';
import { OrderCreactForm } from 'components/forms/order/order-create';
import { OrdersFilterConfirm } from 'components/orders-filter-confirm';
import { format } from 'date-fns';
import { useSubmit } from 'hooks/useSubmit';
import { useTableHeight } from 'hooks/useTableHeight';
import { PageLayout } from 'layouts/page';
import { makeOptionsFromEnum } from 'utils';

import styles from './orders.module.scss';

export const OrdersPage = () => {
  const [ordersStatusFilter, setOrdersStatusFilter] = useState<string[] | null>(
    null
  );

  const navigate = useNavigate();

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);

  const [orderCreactFormIsOpen, setOrderCreactFormIsOpen] = useState(false);
  const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
  const [cancelOrderId, setCancelOrderId] = useState<ID | null>(null);

  const { orders, users, products } = useLoaderData() as {
    orders: IPagginatedDataResponse<IOrder[]>;
    products: IPagginatedDataResponse<IProduct[]>;
    users: IUser[];
  };

  const { submit } = useSubmit(() => {
    orderCreactFormIsOpen
      ? setOrderCreactFormIsOpen(false)
      : setCancelModalIsOpen(false);
  });

  const tableHeight = useTableHeight(tableContainerRef, tableHeadRef);

  const filteredOrders = useMemo(
    () =>
      orders.result.filter((order) =>
        ordersStatusFilter ? ordersStatusFilter.includes(order.status) : true
      ),
    [orders.result, ordersStatusFilter]
  );

  const handleStatusEdit = (status: string, id: ID, userId: ID) => {
    if (status === EOrderStatus.CANCELED) {
      setCancelModalIsOpen(true);
      setCancelOrderId(id);
    } else {
      submit({
        entity: 'updateOrder',
        data: { status, id, userId },
        queryKeys: ['orders'],
      });
    }
  };

  const handleRowClick = (id: ID) => {
    navigate(`/orders/${id}`);
  };

  const handleSubmit = (values: any, entity: string) => {
    submit({
      entity: entity,
      data: { ...values },
      queryKeys: ['orders'],
    });
  };

  const handleCancel = (values: any, entity: string, id: ID) => {
    submit({
      entity: entity,
      data: { ...values, id },
      queryKeys: ['orders'],
    });

    setCancelOrderId(null);
  };

  const handleFiltersChange: TableProps<IOrder>['onChange'] = (_, filters) =>
    setOrdersStatusFilter(filters.status ? (filters.status as string[]) : null);

  const columns: ColumnsType<IOrder> = [
    {
      title: 'Номер заказа',
      dataIndex: 'id',
      key: 'id',
      width: 100,
    },
    {
      title: 'Товар',
      dataIndex: 'product',
      key: 'product',
      width: 250,
      render: (data) => data.name,
    },
    {
      title: 'Заказчик',
      dataIndex: 'user',
      key: 'user',
      width: 150,
      render: (data) => data.fullName,
    },
    {
      title: 'Дата заказа',
      dataIndex: 'date',
      key: 'date',
      width: 130,
      render: (data) => format(new Date(data), 'dd.MM.yyyy'),
    },
    {
      title: 'Стоимость',
      dataIndex: 'product',
      key: 'product',
      width: 150,
      render: (data: IProduct) =>
        `${data.price} ${currencyIcon[data.currency.name]}`,
    },
    {
      title: 'Отправитель',
      dataIndex: 'statusHistory',
      key: 'statusHistory',
      width: 150,
      render: (_, record) =>
        record.statusHistory[record.statusHistory.length - 1]?.changedUser
          ?.email || 'Система',
    },
    {
      title: 'Комментарий',
      dataIndex: 'refundDescription',
      key: 'refundDescription',
      width: 300,
      render: (data) =>
        data ? (
          <Tooltip placement="topLeft" title={data}>
            <div className={styles.comment}>{data}</div>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      filterDropdown: (filterDropdownProps) => (
        <OrdersFilterConfirm
          {...filterDropdownProps}
          ordersStatusFilter={ordersStatusFilter}
        />
      ),
      render: (data: EOrderStatus, record) => (
        <ControlledSelect
          disabled={data === EOrderStatus.CANCELED}
          value={data}
          className={styles.select}
          mode="multiple"
          tagRender={(props) => (
            <Tag color={orderStatusColor[data]}>{props.label}</Tag>
          )}
          defaultValue={EOrderStatus[data]}
          options={makeOptionsFromEnum(orderStatusTranslations)}
          onChange={(value) => {
            handleStatusEdit(value[1], record.id, record.user.id);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <PageLayout
        title="Заказы"
        actions={[
          <Button
            onClick={() => setOrderCreactFormIsOpen(true)}
            icon={<PlusOutlined />}
            type="primary"
          >
            Новый заказ
          </Button>,
        ]}
      >
        <div
          ref={tableContainerRef}
          style={{
            display: 'flex',
            height: tableHeight,
            flex: '1 1 auto',
          }}
        >
          <Table
            className={styles.table}
            rowKey="id"
            bordered
            pagination={false}
            columns={columns}
            dataSource={filteredOrders}
            rowClassName={styles.tableRow}
            scroll={{ y: tableHeight, x: 'max-content' }}
            onChange={handleFiltersChange}
            onRow={(record) => ({
              onClick: () => handleRowClick(record.id),
            })}
            components={{
              header: {
                wrapper: (props: HTMLAttributes<HTMLTableSectionElement>) => (
                  <thead {...props} ref={tableHeadRef} />
                ),
              },
            }}
          />
        </div>
      </PageLayout>
      <OrderCreactForm
        open={orderCreactFormIsOpen}
        users={users}
        products={products.result}
        onSubmit={handleSubmit}
        onCancel={() => {
          setOrderCreactFormIsOpen(false);
        }}
      />
      <OrderCancelForm
        open={cancelModalIsOpen}
        onSubmit={handleCancel}
        id={cancelOrderId}
        onCancel={() => {
          setCancelModalIsOpen(false);
          setCancelOrderId(null);
        }}
      />
    </>
  );
};
