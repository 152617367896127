import { ITableRows } from 'api/types/common';
import { IUser } from 'api/types/user';

export const tableRows: ITableRows<
  Pick<IUser, 'balanceThanks' | 'balanceDiamonds' | 'balanceCoins'>
>[] = [
  { key: 'balanceCoins', label: 'Джинкоины' },
  { key: 'balanceDiamonds', label: 'Алмазы' },
  { key: 'balanceThanks', label: 'Спасибо' },
];
