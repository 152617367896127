import { FC, useEffect, useState } from 'react';

import { Form, Modal } from 'antd';
import { ID } from 'api/types/common';
import { EUserStatus } from 'api/types/enums';
import { IProduct } from 'api/types/products';
import { IUser } from 'api/types/user';
import { SearchSelect } from 'components/search-select';

interface Values {
  productId: ID;
  userId: ID;
}

interface OrderCreactFormProps {
  open: boolean;
  users: IUser[];
  products: IProduct[];
  onSubmit: (values: Values, entity: string) => void;
  onCancel: () => void;
}

export const OrderCreactForm: FC<OrderCreactFormProps> = ({
  open,
  onSubmit,
  onCancel,
  users,
  products,
}) => {
  const [selectedProductId, setSelectedProductId] = useState('');
  const [submittable, setSubmittable] = useState(false);

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const handleClose = () => {
    form.resetFields();
    setSubmittable(false);

    onCancel();
  };

  const USERS_OPTIONS = users
    .filter(({ status }) => status !== EUserStatus.BANNED)
    .map(({ id, fullName }) => ({
      value: id.toString(),
      label: fullName,
    }))
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

  const PRODUCTS_OPTIONS = products
    .filter(({ isAvailable }) => isAvailable)
    .map(({ id, name }) => ({
      value: id.toString(),
      label: name,
    }));

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => {
        setSubmittable(true);
      })
      .catch((err) => {
        setSubmittable(false);
        console.log(err);
      });
  }, [form, values, open]);

  return (
    <Modal
      centered
      key="order"
      open={open}
      title="Создать заказ"
      okText="Создать"
      cancelText="Отменить"
      onCancel={handleClose}
      okButtonProps={{
        disabled: !submittable,
      }}
      onOk={() => {
        form
          .validateFields()
          .then(({ productId, userId }) => {
            form.resetFields();

            const formData = {
              productId: Number(productId),
              userId: Number(userId),
              status: 'PENDING',
              date: new Date().toISOString(),
            };

            onSubmit(formData, 'createOrder');
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="order-create">
        <Form.Item
          name="productId"
          label="Товар"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите товар',
            },
          ]}
        >
          <SearchSelect
            options={PRODUCTS_OPTIONS}
            value={selectedProductId}
            onChange={setSelectedProductId}
          />
        </Form.Item>
        <Form.Item
          name="userId"
          label="Заказчик"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите заказчика',
            },
          ]}
        >
          <SearchSelect options={USERS_OPTIONS} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
