import { FC, useEffect, useState } from 'react';

import { Form, Input, Modal } from 'antd';

interface Values {
  title: string;
  description: string;
  modifier: string;
}

interface AdminCreateFormProps {
  open: boolean;
  onCreate: (values: Values) => void;
  onCancel: () => void;
}

export const AdminCreateForm: FC<AdminCreateFormProps> = ({
  open,
  onCreate,
  onCancel,
}) => {
  const [submittable, setSubmittable] = useState(false);
  const [form] = Form.useForm();
  // Watch all valuess
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => {
        setSubmittable(true);
      })
      .catch(() => {
        setSubmittable(false);
      });
  }, [form, values, open]);

  return (
    <Modal
      open={open}
      centered
      title="Создание нового администратора"
      okText="Создать"
      cancelText="Отменить"
      onCancel={onCancel}
      okButtonProps={{ disabled: !submittable }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="admin-create"
        initialValues={{ modifier: 'public' }}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите почту',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
