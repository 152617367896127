import { FC } from 'react';
import {
  URLSearchParamsInit,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { Card } from 'antd';
import { ITypeFilter } from 'api/types/common';
import { ICurrency } from 'api/types/currency';
import { ITransaction } from 'api/types/transactions';
import { TransactionTable } from 'components/tables/transaction-table';

import styles from '../user.module.scss';

interface IUserTransactionsProps {
  transactions: ITransaction[];
  currencies: ICurrency[];
  activeTab: string;
  typeFilter: ITypeFilter;
  onTypeFilter: (typeFilter: string | null) => void;
  onActiveTab: (activeTab: string) => void;
}

export const UserTransactions: FC<IUserTransactionsProps> = ({
  transactions,
  currencies,
  onActiveTab,
  onTypeFilter,
  activeTab,
  typeFilter,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSearchParams = (params: URLSearchParamsInit) => {
    setSearchParams(params);

    navigate(`${location.pathname}?${params.toString()}`, {
      replace: true,
    });
  };

  return (
    <Card className={styles.tableContainer} title="Транзакции">
      {/* высота блока для таблицы */}
      <TransactionTable
        transactions={transactions}
        searchParams={searchParams}
        activeTab={activeTab}
        typeFilter={typeFilter}
        onSearchParams={handleSearchParams}
        onTypeFilter={onTypeFilter}
        scrollY={455}
        currencies={currencies}
        onActiveTab={onActiveTab}
      />
    </Card>
  );
};
