import { FC, createElement, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  TeamOutlined,
  SmileOutlined,
  TransactionOutlined,
  GroupOutlined,
  ShoppingCartOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import type { MenuProps } from 'antd';
import { NavigationItemPathType } from 'api/types/common';
import { ERole } from 'api/types/enums';
import { useApp } from 'lib/app';

const { Content, Sider } = Layout;

export const MainLayout: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const app = useApp();

  const [collapsed, setCollapsed] = useState(false);

  const [current, setCurrent] = useState<NavigationItemPathType>(
    () => location.pathname.match(/^\/([^/]+)/)?.[1] as NavigationItemPathType
  );

  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    if (current === key && !location.pathname.match(/^\/[^/]+\/([^/]+)/)?.[1])
      return;

    if (key) {
      setCurrent(key as NavigationItemPathType);
      navigate(key);
    }
  };

  const getMenuItems = () => {
    const adminMenuItems: MenuProps['items'] = [
      {
        icon: createElement(TeamOutlined),
        key: 'users',
        label: 'Пользователи',
      },
      {
        icon: createElement(TransactionOutlined),
        key: 'transactions',
        label: 'Транзакции',
      },
      {
        icon: createElement(UnorderedListOutlined),
        key: 'orders',
        label: 'Заказы',
      },
      {
        icon: createElement(ShoppingCartOutlined),
        key: 'shop',
        label: 'Магазин',
      },
      {
        icon: createElement(GroupOutlined),
        key: 'categories',
        label: 'Категории',
      },
    ];

    // TODO: refactor Object.keys(ERole)[1] - need to fix
    if (app.auth.user?.role === Object.keys(ERole)[1]) {
      adminMenuItems.unshift({
        icon: createElement(SmileOutlined),
        key: 'admins',
        label: 'Администраторы',
      });
    }

    return adminMenuItems;
  };

  const renderMenu = () => {
    if (!app.auth.user) return;

    return (
      <Menu
        onClick={handleMenuClick}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[current]}
        selectedKeys={[current]}
        items={getMenuItems()}
      />
    );
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        style={{ position: 'fixed', height: '100vh' }}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        {renderMenu()}
      </Sider>
      <Layout
        style={{
          marginLeft: collapsed ? 80 : 200,
          transition: '.2s',
        }}
      >
        <Content style={{ padding: '24px 16px', overflow: 'hidden' }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
