import { FC } from 'react';

import { ICategory } from 'api/types/category';
import cn from 'clsx';
import { useSubmit } from 'hooks/useSubmit';
import { useDrag, useDrop } from 'react-dnd';
import { reorderQueue } from 'utils';

import styles from './draggable-category.module.scss';
interface DraggableCategoryProps {
  category: ICategory;
  categories: ICategory[];
}

export const DraggableCategory: FC<DraggableCategoryProps> = ({
  category,
  categories,
}) => {
  const { submit } = useSubmit();

  const [{ isOver }, drop] = useDrop({
    accept: 'drop',
    drop: ({ movedIndex }: any) => {
      const reorderedCategories = reorderQueue(
        categories,
        movedIndex,
        category.order
      );

      submit({
        entity: 'reorderCategories',
        data: reorderedCategories,
        queryKeys: ['products', 'categories'],
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [{ opacity }, dragRef] = useDrag(
    () => ({
      name: 'droduct dnd',
      type: 'drop',
      item: { movedIndex: category.order },

      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    [category.order]
  );

  return (
    <span
      className={cn(styles.draggable, { [styles.isOver]: isOver })}
      ref={(node) => {
        dragRef(node);
        drop(node);
      }}
      style={{
        opacity,
      }}
    >
      {category.name}
    </span>
  );
};
