import { currencyTranslations } from 'constants/currencies';

import { FC, useEffect, useState } from 'react';

import { Button, Form, Input, Modal, Switch } from 'antd';
import { ID } from 'api/types/common';
import { ICurrency } from 'api/types/currency';
import { ICreateTransaction } from 'api/types/transactions';
import { SearchSelect } from 'components/search-select';

import styles from './transaction-create.module.scss';

type Values = Omit<ICreateTransaction, 'userId'>;

interface ITransactionCreactFormProps {
  open: boolean;
  userId?: ID;
  currencies: ICurrency[];
  onSubmit: (values: Values, entity: string, id?: ID) => void;
  onCancel: () => void;
}

export const TransactionCreactForm: FC<ITransactionCreactFormProps> = ({
  open,
  onSubmit,
  onCancel,
  currencies,
  userId,
}) => {
  const [isAccrue, setIsAccrue] = useState(true);
  const [submittable, setSubmittable] = useState(false);
  const [form] = Form.useForm();

  // Watch all values
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values, open]);

  useEffect(() => setIsAccrue(true), [open]);

  const handleClose = () => {
    form.resetFields();
    setSubmittable(false);

    onCancel();
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(({ currencyId, amount, details }) => {
        form.resetFields();

        const formData = {
          currencyId: Number(currencyId),
          userId: Number(userId),
          details,
          amount: isAccrue ? Number(amount) : Number(amount) * -1,
        };

        onSubmit(formData, 'createTransaction');
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const CURENCIES_OPTIONS = currencies
    .filter(({ id }) => id !== 3)
    .map(({ id, name }) => ({
      value: id.toString(),
      label: currencyTranslations[name],
    }));

  const Footer = () => (
    <div className={styles.footer}>
      <Switch
        checkedChildren="Начислить"
        unCheckedChildren="Списать"
        checked={isAccrue}
        onChange={() => setIsAccrue((state) => !state)}
      />
      <div className={styles.controls}>
        <Button key="back" onClick={handleClose}>
          Отменить
        </Button>
        <Button
          key="submit"
          type="primary"
          onClick={handleOk}
          disabled={!submittable}
        >
          Создать
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      centered
      key="transaction"
      open={open}
      title="Создать транзакцию"
      onCancel={handleClose}
      footer={[<Footer />]}
    >
      <Form form={form} layout="vertical" name="transaction-create">
        <Form.Item
          name="currencyId"
          label="Валюта"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите валюту',
            },
          ]}
        >
          <SearchSelect options={CURENCIES_OPTIONS} />
        </Form.Item>
        <Form.Item
          name="amount"
          label="Сумма"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите сумму',
            },
            {
              pattern: /^\d+$/,
              message: 'Пожалуйста, укажите число',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="details"
          label="Комментарий"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите комментарий',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
