import { RefObject, useLayoutEffect, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

export const useTableHeight = (
  tableRef: RefObject<Element>,
  tableHeadRef: RefObject<Element>
) => {
  // Keep the Table the height of the parent.
  const [tableHeight, setTableHeight] = useState<number>(0);

  const resizeTable = useDebouncedCallback(() => {
    const table = tableRef.current;
    const tableHead = tableHeadRef.current;

    if (!table || !tableHead) return;
    const { top } = table.getBoundingClientRect();
    const { height } = tableHead.getBoundingClientRect();

    setTableHeight(window.innerHeight - top - height - 40);
  }, 100);

  useLayoutEffect(() => {
    resizeTable();
    window.addEventListener('resize', resizeTable);

    return () => {
      window.removeEventListener('resize', resizeTable);
    };
  }, [resizeTable]);

  return tableHeight;
};
