import {
  deleteCategory,
  postCategory,
  reorderCategories,
  updateCategory,
} from 'api/categories';
import { ICategory } from 'api/types/category';
import { ID } from 'api/types/common';

export const actions = {
  createCategory: async (data: Pick<ICategory, 'name'>) => {
    await postCategory(data);
  },
  updateCategory: async (data: ICategory) => {
    await updateCategory(data);
  },
  deleteCategory: async ({ id }: { id: ID }) => {
    await deleteCategory(id);
  },
  reorderCategories: async (data: Pick<ICategory, 'name'>[]) => {
    await reorderCategories(data);
  },
};
