import { RefObject, useLayoutEffect, useState } from 'react';

export const useAdaptiveTableHeight = (
  tableContainerRef: RefObject<HTMLDivElement> | null,
  tableHeadRef?: RefObject<HTMLTableSectionElement>
) => {
  const [tableHeight, setTableHeight] = useState(0);
  const [tableHeadHeight, setTableHeadHeight] = useState(0);

  useLayoutEffect(() => {
    if (tableContainerRef?.current) {
      const rect = tableContainerRef.current.getBoundingClientRect();
      setTableHeight(rect.height);
    }

    if (tableHeadRef?.current) {
      const rect = tableHeadRef.current.getBoundingClientRect();
      setTableHeadHeight(rect.height);
    }
  }, []);

  return { tableHeight, tableHeadHeight };
};
