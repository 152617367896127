import { IAdminUser } from 'api/types/admin-users';
import { IDataResponse } from 'api/types/common';
import { AxiosResponse } from 'axios';

import { $host } from '..';

export const getAuth = (
  token: string | null
): Promise<AxiosResponse<IDataResponse<IAdminUser>>> =>
  $host.get('/admin/auth', { headers: { Authorization: token } });
