import { cancelOrder, postOrder, updateOrder } from 'api/orders';
import { ID } from 'api/types/common';
import { IOrder, IPostOrderRequestArgs } from 'api/types/orders';

export const actions = {
  createOrder: async (data: IPostOrderRequestArgs) => {
    await postOrder(data);
  },
  updateOrder: async (data: { userId: ID } & Pick<IOrder, 'status' | 'id'>) => {
    await updateOrder(data);
  },
  cancelOrder: async (data: { description: string; id: ID }) => {
    await cancelOrder(data);
  },
};
