import { FC, useState } from 'react';

import { Button, Card } from 'antd';
import { ITypeFilter } from 'api/types/common';
import { ICurrency } from 'api/types/currency';
import { IUser } from 'api/types/user';
import { DataTable } from 'components/data-table';
import { TransactionCreactForm } from 'components/forms/transaction/transaction-create';
import { useSubmit } from 'hooks/useSubmit';

import { tableRows } from './rows';

import styles from '../user.module.scss';

interface ICurrenciesProps {
  user: IUser;
  currencies: ICurrency[];
  activeTab: string;
  typeFilter: ITypeFilter;
}

export const Currencies: FC<ICurrenciesProps> = ({
  user,
  currencies,
  activeTab,
  typeFilter,
}) => {
  const [open, setOpen] = useState(false);

  const { submit } = useSubmit(() => {
    setOpen(false);
  });

  const handleSubmit = (values: any, entity: string) => {
    console.log('Received values of form: ', values);

    submit({
      entity: entity,
      data: values,
      queryKeys: [
        `transactions/${user.id}/${activeTab}/${
          typeFilter[activeTab].filter || 'all'
        }`,
        `user/${user.id}`,
      ],
    });
  };

  return (
    <>
      <Card
        className={styles.content}
        title="Баланс валют"
        actions={[
          <div className={styles.actions}>
            <Button
              className={styles.actionButton}
              size="small"
              onClick={() => setOpen(true)}
              type="primary"
            >
              Редактировать
            </Button>
          </div>,
        ]}
      >
        <DataTable rows={tableRows} value={user} />
      </Card>
      <TransactionCreactForm
        open={open}
        onCancel={() => setOpen(false)}
        onSubmit={handleSubmit}
        userId={user.id}
        currencies={currencies}
      />
    </>
  );
};
