import { TCurencyIcon, TTranslationMap } from 'api/types/common';
import { ECurrency } from 'api/types/enums';

export const currencyTranslations: TTranslationMap<ECurrency> = {
  [ECurrency.COINS]: 'Джинкоины',
  [ECurrency.DIAMONDS]: 'Алмазы',
  [ECurrency.THANKS]: 'Спасибо',
};

export const currencyIcon: TCurencyIcon = {
  [ECurrency.COINS]: '\u{1F4B8}',
  [ECurrency.DIAMONDS]: '\u{1F48E}',
  [ECurrency.THANKS]: '\u{1F64F}',
};
