import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'api';
import { AppProvider } from 'lib/app';

import reportWebVitals from './reportWebVitals';
import { AppRoutes } from './routes';

import './index.css';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <AppRoutes />
      </AppProvider>
    </QueryClientProvider>
  </StrictMode>
);

if (process.env.ENABLE_WEB_VITALS) {
  reportWebVitals(console.log);
}
