import { LoaderFunctionArgs } from 'react-router-dom';

import { getOrder } from 'api/orders';
import { AnyType, ID } from 'api/types/common';

export const loaders = ({ params }: LoaderFunctionArgs<AnyType>) => [
  {
    name: `order/${params.id}`,
    fn: () => getOrder(params.id as ID),
  },
];
