import { getOrders } from 'api/orders';
import { getProducts } from 'api/products';
import { getUsers } from 'api/users';

export const loaders = () => [
  {
    name: 'orders',
    fn: getOrders,
  },
  {
    name: 'users',
    fn: getUsers,
  },
  {
    name: 'products',
    fn: getProducts,
  },
];
