import { ID, IDataResponse, IPagginatedDataResponse } from 'api/types/common';
import { ICreateTransaction, ITransaction } from 'api/types/transactions';
import { AxiosResponse } from 'axios';

import { $host } from '..';

export const getTransactions = (
  currencyId: ID | null,
  type: string | null
): Promise<
  AxiosResponse<IDataResponse<IPagginatedDataResponse<ITransaction[]>>>
> => $host.get('/admin/transactions', { params: { currencyId, type } });

export const getTransactionById = (
  id: ID
): Promise<AxiosResponse<IDataResponse<ITransaction[]>>> =>
  $host.get(`/admin/transactions/${id}`);

export const postTransaction = (
  values: ICreateTransaction
): Promise<AxiosResponse<IDataResponse<ITransaction>>> =>
  $host.post('/admin/transactions', { ...values });
