import { ID, IDataResponse, IPagginatedDataResponse } from 'api/types/common';
import { IProduct } from 'api/types/products';
import { AxiosResponse } from 'axios';

import { $host } from '..';

export const getProducts = (): Promise<
  AxiosResponse<IDataResponse<IPagginatedDataResponse<IProduct[]>>>
> => $host.get('/admin/products');

export const postProduct = (
  data: Omit<IProduct, 'id' | 'currency' | 'category'>
): Promise<AxiosResponse<IDataResponse<IProduct>>> =>
  $host.post('/admin/products', { ...data });

export const updateProduct = (
  data: Omit<IProduct, 'id' | 'currency' | 'category'>,
  id: ID
): Promise<AxiosResponse<IDataResponse<IProduct>>> =>
  $host.patch(`/admin/products/${id}`, { ...data });

export const deleteProduct = (
  id: ID
): Promise<AxiosResponse<IDataResponse<IProduct>>> =>
  $host.delete(`/admin/products/${id}`);
