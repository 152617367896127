import { Navigate } from 'react-router-dom';

import { Typography } from 'antd';
import { useApp } from 'lib/app';

import styles from './login.module.scss';

const { Title } = Typography;

export const LoginPage = () => {
  const app = useApp();

  if (app.auth.token) {
    return <Navigate to="/" />;
  }

  return (
    <div className={styles.login}>
      <div className={styles.header}>
        <Title className={styles.heading}>Вход</Title>
      </div>
      <a href={`${process.env.REACT_APP_API_URL}/api/admin/auth/google`}>
        Зайти через гугл
      </a>
    </div>
  );
};
