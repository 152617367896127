import { ID, IDataResponse, IPagginatedDataResponse } from 'api/types/common';
import { IOrder } from 'api/types/orders';
import { ITransaction } from 'api/types/transactions';
import { IUser } from 'api/types/user';
import { AxiosResponse } from 'axios';

import { $host } from '..';

export const getUsers = (): Promise<AxiosResponse<IDataResponse<IUser[]>>> =>
  $host.get('/admin-users/users');

export const getUserById = (
  id: ID
): Promise<AxiosResponse<IDataResponse<IUser[]>>> =>
  $host.get(`/admin-users/users/${id}`);

export const getUserOrders = (
  id: ID
): Promise<AxiosResponse<IDataResponse<IPagginatedDataResponse<IOrder[]>>>> =>
  $host.get(`/admin-users/users/${id}/orders`);

export const getUserTransactions = (
  currencyId: ID | null,
  type: string | null,
  id: ID
): Promise<
  AxiosResponse<IDataResponse<IPagginatedDataResponse<ITransaction[]>>>
> =>
  $host.get(`/admin-users/users/${id}/transactions`, {
    params: { currencyId, type },
  });

export const postUser = (
  values: Pick<
    IUser,
    | 'telegramId'
    | 'status'
    | 'fullName'
    | 'city'
    | 'employmentDate'
    | 'birthDate'
  >
): Promise<AxiosResponse<IDataResponse<IUser>>> =>
  $host.post('/admin-users/users', { ...values });

export const banUser = (
  values: Pick<IUser, 'telegramId'>
): Promise<AxiosResponse<IDataResponse<IUser>>> =>
  $host.post('/admin-users/banned-user', { ...values });

export const deleteUser = (
  id: ID
): Promise<AxiosResponse<IDataResponse<IUser>>> =>
  $host.delete(`/admin-users/users/${id}`);

export const updateUser = (
  values: Pick<
    IUser,
    | 'telegramId'
    | 'status'
    | 'fullName'
    | 'city'
    | 'employmentDate'
    | 'birthDate'
  >,
  id: ID
): Promise<AxiosResponse<IDataResponse<IUser>>> =>
  $host.patch(`/admin-users/users/${id}`, { ...values });
