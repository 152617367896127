import { getCategories } from 'api/categories';
import { getCurrencies } from 'api/currencies';
import { getProducts } from 'api/products';

export const loaders = () => [
  {
    name: 'products',
    fn: getProducts,
  },
  {
    name: 'categories',
    fn: getCategories,
  },
  {
    name: 'currencies',
    fn: getCurrencies,
  },
];
