import { ICategory } from 'api/types/category';
import { ID, IDataResponse, IPagginatedDataResponse } from 'api/types/common';
import { AxiosResponse } from 'axios';

import { $host } from '..';

export const getCategories = (): Promise<
  AxiosResponse<IDataResponse<IPagginatedDataResponse<ICategory[]>>>
> => $host.get('/admin/categories');

export const postCategory = (
  values: Pick<ICategory, 'name'>
): Promise<AxiosResponse<IDataResponse<ICategory>>> =>
  $host.post('/admin/categories', { ...values });

export const updateCategory = ({
  id,
  name,
}: ICategory): Promise<AxiosResponse<IDataResponse<ICategory>>> =>
  $host.patch(`/admin/categories/${id}`, { name });

export const deleteCategory = (
  id: ID
): Promise<AxiosResponse<IDataResponse<ICategory[]>>> =>
  $host.delete(`/admin/categories/${id}`);

export const reorderCategories = (
  data: Pick<ICategory, 'name'>[]
): Promise<
  AxiosResponse<IDataResponse<IPagginatedDataResponse<ICategory[]>>>
> => $host.put('/admin/categories/order', { orders: data });
