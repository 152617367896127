import { deleteProduct, postProduct, updateProduct } from 'api/products';
import { ID } from 'api/types/common';
import { IProduct } from 'api/types/products';

export const actions = {
  createProduct: async (data: {
    values: Omit<IProduct, 'id' | 'currency' | 'category'>;
  }) => {
    await postProduct(data.values);
  },

  updateProduct: async (data: {
    values: Omit<IProduct, 'id' | 'currency' | 'category'>;
    id: ID;
  }) => {
    await updateProduct(data.values, data.id);
  },

  deleteProduct: async ({ id }: { id: ID }) => {
    await deleteProduct(id);
  },
};
