import { LoaderFunctionArgs } from 'react-router-dom';

import { getCurrencies } from 'api/currencies';
import { getTransactions } from 'api/transactions';
import { AnyType } from 'api/types/common';

export const loaders = ({ request }: LoaderFunctionArgs<AnyType>) => [
  {
    name: () => {
      const currencyId = new URL(request.url).searchParams.get('currencyId');
      const type = new URL(request.url).searchParams.get('type');

      return `transactions/${currencyId}/${type}`;
    },
    fn: () => {
      const currencyId =
        new URL(request.url).searchParams.get('currencyId') || '1';

      const type = new URL(request.url).searchParams.get('type') || 'all';

      return getTransactions(currencyId, type === 'all' ? null : type);
    },
  },
  {
    name: 'currencies',
    fn: getCurrencies,
  },
];
